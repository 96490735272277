import React from "react";
import { useGlobalValues } from "../hooks/useGlobalValues";

const GlobalValuesContext = React.createContext({});

export const GlobalValuesContextProvider = ({ children }) => {
  const value = useGlobalValues();

  return (
    <GlobalValuesContext.Provider value={{ ...value }}>
      {children}
    </GlobalValuesContext.Provider>
  );
};

export const useGlobalValuesContext = () => {
  return React.useContext(GlobalValuesContext);
};
