import { colors } from "../foundations/colors";

export const tabStyles = {
  parts: ["tab", "tabpanel"],
  baseStyle: {
    tab: {
      bgColor: "transparent",
      fontWeight: 700,
      letterSpacing: 0.5,
      color: colors.mandala.black[500],
      border: "none",
      outline: "none",
      _selected: {
        color: colors.mandala.blue[600],
        borderBottom: "4px solid"
      }
    },
    tabpanel: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
};
