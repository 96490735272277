import React from "react";

import "@reach/dialog/styles.css";
import "@reach/menu-button/styles.css";
import "@reach/tooltip/styles.css";
import "@reach/combobox/styles.css";

import "tachyons/css/tachyons.min.css";
import "src/xterm.css";

import "@praedictio/tesseract-component/build/Globals/styles.css";
import "@praedictio/tesseract-component/build/Component/Loader/loader.css";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import "react-popper-tooltip/dist/styles.css";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "react-toastify/dist/ReactToastify.css";

import "react-contexify/dist/ReactContexify.min.css";

import "../App.css";
import "../style.css";
import "../toast.css";

import "./Table.css";

import ContextProvider from "../contexts";
import { ChakraProvider } from "@chakra-ui/react";

import "@fontsource/ibm-plex-sans";
import "@fontsource/ibm-plex-mono";
import "@fontsource/ibm-plex-mono/700.css";
import "@fontsource/ibm-plex-sans/500.css";
import mandalaTheme from "../theme/index";
import { hotjar } from "react-hotjar";

const CustomApp = ({ Component, pageProps }) => {
  const HOTJAR_ID = process.env.HOTJAR_ID;
  const HOTJAR_SV = process.env.HOTJAR_SNIPPET_VERSION;
  const shouldInitializeHotjar = Boolean(HOTJAR_ID) && Boolean(HOTJAR_SV);

  // Initialize Hotjar
  React.useEffect(() => {
    if (!shouldInitializeHotjar) {
      return;
    }

    hotjar.initialize(HOTJAR_ID, HOTJAR_SV);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChakraProvider resetCSS={false} theme={mandalaTheme}>
      <ContextProvider>
        <Component {...pageProps} />
      </ContextProvider>
    </ChakraProvider>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// CustomApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default CustomApp;
