export const STORAGE_VALUE = {
  BANNER: "BANNER",
  SIDEBAR_COLLAPSE: "SIDEBAR_COLLAPSE",
  CDP_COLLAPSE: "CDP_COLLAPSE",
  DELETED_PROJECTS: "DELETED_PROJECTS",
  PROJECT_NAME: "PROJECT_NAME",

  PERSONAL_SETTING_COLLAPSE: "PERSONAL_SETTING_COLLAPSE",

  IS_CDP_USER: "IS_CDP_USER",
  IS_FREE_USER: "IS_FREE_USER",

  USER_TIER: "USER_TIER",

  ONBOARDING: "ONBOARDING",
  ONBOARDING_SECTION: "ONBOARDING_SECTION",
  ONBOARDING_SHOULD_REFETCH: "ONBOARDING_SHOULD_REFETCH",
  ONBOARDING_SECTION_CONFIG: "ONBOARDING_SECTION_CONFIG",
  ONBOARDING_ENABLE_PAGE_GUARD: "ONBOARDING_ENABLE_PAGE_GUARD"
};
