import { useRequest } from "src/contexts/request";
import { useMutateData } from "src/hooks/utils/useDataQuery";

export const useUserLoginWithGoogleMutation = ({ config }) => {
  const { post, STATION_HOSTNAME } = useRequest();

  const _loginWithGoogle = async tokenId => {
    try {
      const { json, headers } = await post(`${STATION_HOSTNAME}/google_login`, {
        json: { token_id: tokenId }
      });
      return { json, headers };
    } catch (error) {
      throw error;
    }
  };

  return useMutateData(_loginWithGoogle, { ...config });
};
