import { colors } from "../foundations/colors";

export const tableStyles = {
  parts: ["table", "thead", "tbody", "tr", "th", "td"],
  baseStyle: {
    table: {},
    thead: {},
    th: {},
    tbody: {},
    tr: {},
    td: {
      whiteSpace: "nowrap",
      textOverflow: "clip"
    }
  },
  variants: {
    "data-table": {
      table: {
        w: "100%",

        borderCollapse: "separate",
        borderSpacing: 0
      },

      thead: {
        position: "-webkit-sticky",
        position: "sticky",
        top: "0",
        left: 0,
        zIndex: 5
      },
      tbody: {
        pos: "relative"
      },
      tr: {
        height: "inherit"
      },
      th: {
        position: "relative",
        p: 0,
        m: 0,
        height: "inherit",
        textTransform: "none",
        boxShadow: "sm",

        fontFamily: "IBM Plex Mono",
        fontWeight: "700",

        borderRight: "solid 1px",
        borderTop: "solid 1px",
        borderBottom: "solid 1px",
        borderColor: colors.mandala.black[300],
        _first: {
          borderLeft: "solid 1px",
          borderColor: colors.mandala.black[300]
        }
      },
      td: {
        p: 0,
        m: 0,
        height: "inherit",
        textTransform: "none",
        bg: "white",
        fontFamily: "IBM Plex Mono",

        borderRight: "solid 1px",
        borderBottom: "solid 1px",
        borderColor: colors.mandala.black[300],
        _first: {
          borderLeft: "solid 1px",
          borderColor: colors.mandala.black[300]
        }
      }
    }
  }
};
