import { colors } from "../foundations/colors";

export const menuStyles = {
  parts: ["button", "list", "item", "groupTitle", "command", "divider"],
  baseStyle: {
    list: {
      backgroundColor: "white",
      shadow: "large",
      border: "solid 1px",
      borderColor: colors.mandala.black[300]
    },
    item: {
      bgColor: "white",
      border: "none",
      cursor: "pointer"
    }
  }
};
