import { colors } from "../foundations/colors";

export const badgeStyles = {
  variants: {
    "new-badge": ({ colorScheme = "blue" }) => ({
      color: colors.mandala.black[50],
      bgColor: colors.mandala[colorScheme][500],
      fontSize: "0.625rem",
      borderRadius: "1.25rem",
      textTransform: "capitalize",
      padding: "0 3px"
    })
  }
};
