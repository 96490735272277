import React from "react";
import { STORAGE_VALUE } from "src/constants/storage";
import isNull from "lodash/isNull";

/**
 * This hook controls about mandala-ui app localStorage config and settings.
 */
export const useGlobalValues = () => {
  const [showBanner, _setShowBanner] = React.useState(false);
  const [collapseSidebar, _setCollapseSidebar] = React.useState(false);
  const [collapseCDP, _setCollapseCDP] = React.useState(true);
  const [deletedProjectIdList, _setDeletedProjectIdList] = React.useState([]);
  const [collapsePersonalSetting, _setCollapsePersonalSetting] =
    React.useState(true);
  const [projectName, _setProjectName] = React.useState("");

  const setBannerState = React.useCallback(
    state => {
      _setShowBanner(state);
      localStorage.setItem(STORAGE_VALUE.BANNER, state);
    },
    [_setShowBanner]
  );

  const setCollapseSidebar = React.useCallback(
    state => {
      _setCollapseSidebar(state);
      localStorage.setItem(STORAGE_VALUE.SIDEBAR_COLLAPSE, state);
    },
    [_setCollapseSidebar]
  );

  const setCollapseCDP = React.useCallback(
    state => {
      _setCollapseCDP(state);
      localStorage.setItem(STORAGE_VALUE.CDP_COLLAPSE, state);
    },
    [_setCollapseCDP]
  );

  const setCollapsePersonalSetting = React.useCallback(
    state => {
      _setCollapsePersonalSetting(state);
      localStorage.setItem(STORAGE_VALUE.PERSONAL_SETTING_COLLAPSE, state);
    },
    [_setCollapsePersonalSetting]
  );

  const setProjectName = React.useCallback(
    state => {
      _setProjectName(state);
      sessionStorage.setItem(STORAGE_VALUE.PROJECT_NAME, state);
    },
    [_setProjectName]
  );

  const setDeletedProjectIdList = React.useCallback(
    state => {
      _setDeletedProjectIdList(state);
      const stringifiedState = JSON.stringify(state);
      localStorage?.setItem(STORAGE_VALUE.DELETED_PROJECTS, stringifiedState);
    },
    [_setDeletedProjectIdList]
  );

  const toggleCollapseSidebar = () => setCollapseSidebar(!collapseSidebar);

  const toggleCollapseCDP = () => setCollapseCDP(!collapseCDP);
  const toggleOpenCDP = () => setCollapseCDP(true);

  const toggleCollapsePersonalSetting = () =>
    setCollapsePersonalSetting(!collapsePersonalSetting);
  const toggleOpenPersonalSetting = () => setCollapsePersonalSetting(true);

  // Define Banner and Sidebar Collapse status on Component Mount
  React.useEffect(() => {
    const storageBanner = localStorage.getItem(STORAGE_VALUE.BANNER);
    const storageSidebar = localStorage.getItem(STORAGE_VALUE.SIDEBAR_COLLAPSE);
    const storageDeletedProjects = localStorage?.getItem(
      STORAGE_VALUE.DELETED_PROJECTS
    );

    if (!isNull(storageDeletedProjects)) {
      setDeletedProjectIdList(JSON.parse(storageDeletedProjects || "[]"));
    }
    setBannerState(isNull(storageBanner) || JSON.parse(storageBanner));
    setCollapseSidebar(isNull(storageSidebar) || JSON.parse(storageSidebar));
  }, [showBanner, setBannerState, setCollapseSidebar, setDeletedProjectIdList]);

  return {
    showBanner,
    setBannerState,
    collapseSidebar,
    setCollapseSidebar,

    collapsePersonalSetting,
    toggleCollapsePersonalSetting,
    toggleOpenPersonalSetting,

    deletedProjectIdList,
    setDeletedProjectIdList,

    projectName,
    setProjectName,

    toggleCollapseSidebar,
    collapseCDP,
    toggleCollapseCDP,
    toggleOpenCDP
  };
};
