import { colors } from "../foundations/colors";

// Source Docs:
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/progress.ts
export const progressStyles = {
  baseStyle: ({ trackColor }) => {
    return {
      track: { rounded: "full", border: "solid 1px" },
      filledTrack: {
        bg: trackColor || colors.tertiary
      }
    };
  }
};
