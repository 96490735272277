import {
  useQuery,
  useInfiniteQuery,
  usePaginatedQuery,
  useMutation,
  MutationFunction,
  MutationOptions,
  AnyQueryKey,
  InfiniteQueryOptions,
  InfiniteQueryFunction,
  QueryOptions,
  QueryFunction
} from "react-query";
import { createErrorToast } from "../useToast";
// uses router instead of useRouter for non-react function
import router from "next/router";
import { clearToken } from "src/utils/tokenHelper";

/**
 * Function for handling error with toast
 * @param {*} error: Error thrown by the caller fn
 * @returns {void}
 */
export const defaultErrorHandler = async error => {
  const errorMessage = error?.message || "Failed to process data";
  // if error is 401 (auth related), then do nothing
  // since it is handled via request.js
  if (error?.statusCode === 401) {
    clearToken();
    await router.push("/");
    createErrorToast(errorMessage, { toastId: "AUTH_FAILED" });
    return;
  }
  createErrorToast(errorMessage);
};

/**
 * wrapper for useQuery, so we can easily change data fetching lib
 * @param {QueryKey} key
 * @param {QueryFunction} fetchFn
 * @param {QueryOptions} config
 * @returns
 */
export const useFetchData = (key, fetchFn, config) => {
  // create a default retry strategy on every request by respecting
  // the existing `config.retry`value for compatibility
  let withConfig;

  if (config?.retry) {
    withConfig = {
      ...config,
      retry: config.retry
    };
  } else {
    withConfig = {
      ...config,
      retry: (failureCount, error) => {
        // when an error occurs on the client end, we don't need to retry the request
        // otherwise, let's give it 3 chances (react-query's default)
        if (error.statusCode) {
          const errorCode = ~~error.statusCode;
          const errorFromClient = errorCode >= 400 && errorCode < 500;

          if (errorFromClient) {
            return false;
          }
        }
      }
    };
  }

  const { data, ...rest } = useQuery(key, fetchFn, {
    onError: defaultErrorHandler,
    ...withConfig
  });
  return { data, ...rest };
};

/**
 * wrapper for paginated useQuery, so we can easily change data fetching lib
 * @param {AnyQueryKey} key
 * @param {QueryFunctionContext} fetchFn
 * @param {UseQueryOptions} config
 * @returns
 */
export const useFetchPaginatedData = (key, fetchFn, config) => {
  return usePaginatedQuery(key, fetchFn, {
    onError: defaultErrorHandler,
    ...config
  });
};

/**
 * wrapper for useInfiniteQuery, so we can easily change data fetching lib
 * @param {AnyQueryKey} key
 * @param {InfiniteQueryFunction} fetchFn
 * @param {InfiniteQueryOptions} config
 * @returns
 */
export const useFetchInfiniteData = (key, fetchFn, config) => {
  return useInfiniteQuery(key, fetchFn, {
    onError: defaultErrorHandler,
    ...config
  });
};

/**
 * wrapper for useMutation, so we can easily change data fetching lib
 * @param {MutationFunction} fetchFn
 * @param {MutationOptions} config
 */

export const useMutateData = (mutateFn, config = {}) => {
  const [mutateAsync, { ...props }] = useMutation(mutateFn, {
    onError: defaultErrorHandler,
    ...config
  });

  return { mutateAsync, ...props };
};
