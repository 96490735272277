export const popoverStyles = {
  parts: ["popper", "content"],
  variants: {
    "match-width": {
      popper: {
        maxWidth: "100%",
        width: "100%"
      }
    }
  }
};
