import React from "react";
import { toast, Slide } from "react-toastify";
import { CloseButton, MyToast } from "src/components/MandalaUI/Toast";

export const TOAST_VARIANT = {
  SOLID: "solid",
  OUTLINE: "outline"
};

export const TOAST_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info"
};

const _defaultConfig = {
  autoClose: 3000,
  draggable: false,
  hideProgressBar: true,
  transition: Slide,
  customComponent: null
};

/**
 *
 * @param {string} type toast type, see TOAST_TYPE map
 * @param {string} message message for toast
 * @param {Object} config config for toast, this config will override toast container config, see https://github.com/fkhadra/react-toastify
 */
const createToast = (message, _config) => {
  const config = { ..._defaultConfig, ..._config };
  const { variant = TOAST_VARIANT.OUTLINE, type = TOAST_TYPE.SUCCESS } = config;

  return toast(
    <MyToast message={message} variant={variant} type={type} {...config} />,
    {
      type,
      className: `Toastify__toast_variant_${variant}`,
      closeButton: <CloseButton variant={variant} />,
      ...config
    }
  );
};

const createInfoToast = (message, config) => {
  return createToast(message, { type: TOAST_TYPE.INFO, ...config });
};

const createErrorToast = (message, config) => {
  return createToast(message, { type: TOAST_TYPE.ERROR, ...config });
};

const createWarningToast = (message, config) => {
  return createToast(message, { type: TOAST_TYPE.WARNING, ...config });
};

const createSuccessToast = (message, config) => {
  return createToast(message, { type: TOAST_TYPE.SUCCESS, ...config });
};

const updateToast = (initialToast, message, _config) => {
  const config = { ..._defaultConfig, ..._config };
  const { variant = TOAST_VARIANT.OUTLINE, type = TOAST_TYPE.SUCCESS } = config;

  toast.update(initialToast, {
    render: (
      <MyToast message={message} variant={variant} type={type} {...config} />
    ),
    type,
    className: `Toastify__toast_variant_${variant}`,

    ...config
  });
};

const dismissAllToast = () => {
  toast.dismiss();
};

const dismissToast = toastId => {
  toast.dismiss(toastId);
};

export {
  createToast,
  updateToast,
  createInfoToast,
  createErrorToast,
  createWarningToast,
  createSuccessToast,
  dismissAllToast,
  dismissToast
};
