import { colors } from "../foundations/colors";

export const textStyles = {
  baseStyle: {
    margin: 0,
    padding: 0,
    fontSize: ".875em",
    fontWeight: 400,
    color: colors.mandala.black[900]
  },
  // Can't overide "as" Props
  // Read More: https://github.com/chakra-ui/chakra-ui/issues/3287
  variants: {
    bold: {
      fontSize: ".875em",
      fontWeight: 700
    },
    subtitle: {
      fontSize: ".75em"
    },
    "subtitle bold": {
      fontSize: ".75em",
      fontWeight: 700
    },
    "virtualized-table-header": {
      p: "0",
      m: "0",
      px: ".75em",
      w: "inherit",
      justify: "center",
      align: "center",
      fontSize: "sm",
      fontWeight: "700",
      fontFamily: "mono",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflowX: "hidden",
      textAlign: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: `translate(-50%, -50%)`
    },
    "virtualized-table-cell": {
      whiteSpace: "pre",
      p: "0",
      px: "1em",
      w: "100%",
      m: "0",
      textOverflow: "ellipsis",
      overflowX: "hidden"
    }
  }
};
