/**
 * Sync local storage
 */

const TOKEN_KEY = "TESSERACT_AUTH";

/**
 *
 * @param {string} token
 * @description set TESSERACT_AUTH local storage to token
 * @returns {void}
 */
export const setToken = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

/**
 * @description clear token from local storage
 * @returns {void}
 */
export const clearToken = () => {
  localStorage.clear();
};

/**
 * @description get token from local storage TESSERACT_AUTH
 * @returns {string | void} JWT or void
 *
 * @example
 * const token = getToken();
 *
 */
export const getToken = () => {
  try {
    const { token } = JSON.parse(localStorage.getItem(TOKEN_KEY));
    return token;
  } catch {
    return;
  }
};

/**
 * @description get user info from local storage TESSERACT_AUTH
 * @returns {object} userInfo or {}
 *
 * @example
 * const userInfo = getUserInfo();
 */
export const getUserInfo = () => {
  try {
    const { userInfo } = JSON.parse(localStorage.getItem(TOKEN_KEY));
    return userInfo;
  } catch {
    return {};
  }
};

/**
 * @description get admin status from local storage TESSERACT_AUTH
 * @returns {boolean} isAdmin
 *
 * @example
 * const isAdmin = checkIsAdmin();
 */
export const checkIsAdmin = () => {
  const { access } = getUserInfo();
  return access === "root" || access === "admin";
};

/**
 * @description get super admin status from local storage TESSERACT_AUTH
 * @returns {boolean} isSuperAdmin
 *
 * @example
 * const isSuperAdmin = checkIsSuperAdmin();
 */
export const checkIsSuperAdmin = () => {
  const { access } = getUserInfo();
  return access === "root";
};
