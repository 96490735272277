import { colors } from "../foundations/colors";

export const switchStyles = {
  parts: ["container", "track", "thumb"],
  baseStyle: {
    track: {
      _checked: {
        bg: colors.mandala.green[500]
      }
    }
  }
};
