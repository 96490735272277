import { colors } from "../foundations/colors";

export const numberInputStyles = {
  parts: ["field"],
  variants: {
    outline: props => {
      const { isRequired, isDisabled } = props;
      return {
        field: {
          ...(isDisabled && { bgColor: colors.mandala.black[200] }),
          borderRadius: "3px",
          borderColor: isRequired
            ? colors.mandala.red[300]
            : colors.mandala.black[300],
          _focus: {
            boxShadow: `0 0 0 0 ${colors.mandala.blue[500]}`,
            borderColor: colors.mandala.blue[500]
          }
        }
      };
    }
  },
  defaultProps: {
    variant: "outline"
  }
};
