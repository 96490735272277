import { extendTheme } from "@chakra-ui/react";
import { buttonStyles } from "./components/button";
import { checkboxStyles } from "./components/checkbox";
import { headingStyles } from "./components/heading";
import { inputStyles } from "./components/inputs";
import { numberInputStyles } from "./components/numberInput";
import { textStyles } from "./components/text";
import { radioboxStyles } from "./components/radiobox";
import { dividerStyles } from "./components/divider";
import { popoverStyles } from "./components/Popover";
import { tableStyles } from "./components/table";
import { tabStyles } from "./components/tab";
import { globalStyles } from "./styles";
import { menuStyles } from "./components/menu";
import { switchStyles } from "./components/switch";
import { linkStyles } from "./components/link";
import { progressStyles } from "./components/progress";
import { badgeStyles } from "./components/badge";

// Docs: https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components
const theme = extendTheme({
  ...globalStyles,
  components: {
    Heading: headingStyles,
    Text: textStyles,
    Button: buttonStyles,
    Input: inputStyles,
    NumberInput: numberInputStyles,
    Checkbox: checkboxStyles,
    Radio: radioboxStyles,
    Divider: dividerStyles,
    Popover: popoverStyles,
    Progress: progressStyles,
    Table: tableStyles,
    Menu: menuStyles,
    Tabs: tabStyles,
    Switch: switchStyles,
    Link: linkStyles,
    Badge: badgeStyles
  }
});

export default theme;
