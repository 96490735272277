import { colors } from "../foundations/colors";

export const headingStyles = {
  baseStyle: {
    margin: 0,
    padding: 0,
    fontFamily: "IBM Plex Sans",
    fontWeight: "bold",
    color: colors.mandala.black[900]
  },
  // Can't overide "as" Props
  // Read More: https://github.com/chakra-ui/chakra-ui/issues/3287
  variants: {
    h1: {
      fontSize: "2.375em"
    },
    h2: {
      fontSize: "1.875em"
    },
    h3: {
      fontSize: "1.5em"
    },
    h4: {
      fontSize: "1.25em"
    },
    h5: {
      fontSize: "1em"
    }
  },
  defaultProps: {
    variant: "h2"
  }
};
