import { colors } from "../foundations/colors";

export const checkboxStyles = {
  parts: ["container", "control", "label", "icon"],
  baseStyle: {
    control: {
      borderColor: colors.mandala.black[300],
      bgColor: colors.mandala.black[50],
      _invalid: {
        borderColor: colors.mandala.black[300]
      },
      _checked: {
        borderColor: colors.mandala.blue[700],
        bgColor: colors.mandala.blue[700],
        _hover: {
          borderColor: colors.mandala.blue[500],
          bgColor: colors.mandala.blue[500],
          _invalid: {
            borderColor: colors.mandala.blue[500]
          }
        },
        _invalid: {
          borderColor: colors.mandala.blue[700]
        }
      },
      _indeterminate: {
        borderColor: colors.mandala.blue[700],
        bgColor: colors.mandala.blue[700],
        _hover: {
          borderColor: colors.mandala.blue[500],
          bgColor: colors.mandala.blue[500],
          _invalid: {
            borderColor: colors.mandala.blue[500]
          }
        },
        _invalid: {
          borderColor: colors.mandala.blue[700]
        }
      }
    }
  }
};
