import React from "react";

import { Box, Flex, Text, IconButton, Icon } from "@chakra-ui/react";
import { RiCloseFill } from "react-icons/ri";
import {
  IoCheckmarkCircleOutline,
  IoWarningOutline,
  IoInformationCircleOutline
} from "react-icons/io5";
import { TOAST_TYPE, TOAST_VARIANT } from "src/hooks/useToast";

export const MyToast = ({ message, title, variant, type, customComponent }) => {
  const isOutline = variant === TOAST_VARIANT.OUTLINE;
  const hasTitle = Boolean(title);
  const hasCustomComponent = Boolean(customComponent);

  const generateIcon = () => {
    switch (type) {
      case TOAST_TYPE.SUCCESS:
        return {
          color: "mandala.green.500",
          ToastIcon: IoCheckmarkCircleOutline
        };

      case TOAST_TYPE.ERROR:
        return { color: "mandala.red.500", ToastIcon: IoWarningOutline };

      case TOAST_TYPE.WARNING:
        return { color: "mandala.yellow.500", ToastIcon: IoWarningOutline };

      case TOAST_TYPE.INFO:
        return {
          color: "mandala.blue.500",
          ToastIcon: IoInformationCircleOutline
        };

      default:
        return { color: "mandala.black.500", ToastIcon: IoWarningOutline };
    }
  };

  const { color, ToastIcon } = generateIcon();
  const textStyle = {
    color: isOutline ? "mandala.black.900" : "mandala.black.50",
    textShadow: "md"
  };

  return (
    <Flex fontFamily="IBM Plex Sans" align="center">
      <Icon
        as={ToastIcon}
        color={isOutline ? color : "white"}
        w="25px"
        h="25px"
        mr=".75em"
      />

      {hasCustomComponent ? (
        customComponent
      ) : (
        <Box pr="0.75em">
          {hasTitle && (
            <Text variant="bold" {...textStyle}>
              {title}
            </Text>
          )}
          <Text {...textStyle}>{message}</Text>
        </Box>
      )}
    </Flex>
  );
};

export const CloseButton = ({ closeToast, variant }) => {
  const isOutline = variant === TOAST_VARIANT.OUTLINE;

  return (
    <IconButton
      pos="absolute"
      top="0"
      right="0"
      icon={<RiCloseFill />}
      variant="transparent"
      rounded="full"
      color={isOutline ? "mandala.black.300" : "mandala.black.50"}
      onClick={closeToast}
      _hover={{
        bg: "transparent",
        color: isOutline ? "mandala.red.500" : "mandala.black.300"
      }}
    />
  );
};
