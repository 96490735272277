import { colors } from "../foundations/colors";

export const inputStyles = {
  variants: {
    "ddl-outline": props => {
      const { isRequired, isDisabled } = props;
      return {
        field: {
          border: "1px solid",
          ...(isDisabled && {
            bgColor: colors.mandala.black[200],
            color: colors.mandala.black[500]
          }),
          borderRadius: "20px",
          borderColor: isRequired
            ? colors.mandala.red[500]
            : colors.mandala.black[300],
          _focus: {
            outline: "none",
            borderColor: colors.mandala.blue[500]
          },
          _disabled: {
            opacity: 1
          }
        }
      };
    },
    outline: props => {
      const { isRequired, isDisabled } = props;
      return {
        field: {
          ...(isDisabled && {
            bgColor: colors.mandala.black[200],
            color: colors.mandala.black[500]
          }),
          borderRadius: "3px",
          borderColor: isRequired
            ? colors.mandala.red[300]
            : colors.mandala.black[300],
          _focus: {
            outline: "none",
            borderColor: colors.mandala.blue[500]
          },
          _disabled: {
            opacity: 1
          }
        }
      };
    }
  }
};
