import React from "react";
import { AuthContextProvider } from "./auth";
import { RequestContextProvider } from "./request";
import { ToastContainer, toast } from "react-toastify";
import { GlobalValuesContextProvider } from "./globalValues";

const ContextProvider = ({ children, auth = {}, request = {}, query = {} }) => {
  return (
    <RequestContextProvider initialValues={request}>
      <GlobalValuesContextProvider>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} />
        <AuthContextProvider initialValues={auth}>
          {children}
        </AuthContextProvider>
      </GlobalValuesContextProvider>
    </RequestContextProvider>
  );
};

export default ContextProvider;
