import React from "react";
import ky from "ky/umd";
import { getToken } from "src/utils/tokenHelper";

// request timeout unit is always millisecond
const defaultRequestTimeout = 1000 * 60 * 30;

const RequestContext = React.createContext();
const RequestContextProvider = ({ children, initialValue = {} }) => {
  const STATION_HOSTNAME = process.env.REACT_APP_ANALYTIC_HOSTNAME;

  const RESPONSE_TYPE_MAP = {
    BLOB: "BLOB",
    JSON: "JSON"
  };

  const defaultResponseErrorRTO = {
    status: 408,
    json: {
      data: {},
      message: "Request timeout, please try again"
    }
  };

  const _getHeaderAndPayloadFromResponse = async (response, type) => {
    const { headers, status } = response;
    switch (type) {
      case RESPONSE_TYPE_MAP.BLOB: {
        const blob = response?.blob
          ? await response.blob()
          : defaultResponseErrorRTO.json;

        return { headers, blob, status };
      }
      default: {
        const json = response?.json
          ? await response.json()
          : defaultResponseErrorRTO.json;

        return { headers, json, status };
      }
    }
  };

  /**
   *
   * @param {error} defaultError
   *
   * handling http error, if status is 401, clear user login data and bring user to login
   * else, put message from backend to error.message
   *
   */
  const _handleHTTPError = async error => {
    const { response } = error;
    if (response) {
      const payload = await _getHeaderAndPayloadFromResponse(response);

      const { json, status } = payload;
      const error = new Error();
      error.message = payload.json.message;
      error.statusCode = status;
      error.json = json;
      throw error;
    }

    const _error = new Error();
    _error.message = defaultResponseErrorRTO.json.message;
    _error.statusCode = defaultResponseErrorRTO.status;
    _error.json = defaultResponseErrorRTO.json;
    throw _error;
  };

  const get = async (url, payload, type = RESPONSE_TYPE_MAP.JSON) => {
    try {
      const response = await ky.get(url, {
        ...payload,
        headers: { Authorization: getToken() },
        timeout: defaultRequestTimeout
      });
      return await _getHeaderAndPayloadFromResponse(response, type);
    } catch (error) {
      await _handleHTTPError(error);
    }
  };

  const post = async (url, payload, type = RESPONSE_TYPE_MAP.JSON) => {
    try {
      const response = await ky.post(url, {
        ...payload,
        headers: { Authorization: getToken() },
        timeout: defaultRequestTimeout
      });
      return await _getHeaderAndPayloadFromResponse(response, type);
    } catch (error) {
      await _handleHTTPError(error);
    }
  };

  const put = async (url, payload) => {
    try {
      const response = await ky.put(url, {
        ...payload,
        headers: { Authorization: getToken() }
      });
      return await _getHeaderAndPayloadFromResponse(response);
    } catch (error) {
      await _handleHTTPError(error);
    }
  };

  // Name is del because "delete" is already a name of function from javascript.
  const del = async (url, payload) => {
    try {
      const response = await ky.delete(url, {
        ...payload,
        headers: { Authorization: getToken() }
      });
      return await _getHeaderAndPayloadFromResponse(response);
    } catch (error) {
      await _handleHTTPError(error);
    }
  };

  return (
    <RequestContext.Provider
      value={{
        get,
        post,
        put,
        del,
        STATION_HOSTNAME,
        RESPONSE_TYPE_MAP,
        getToken,
        ...initialValue
      }}
    >
      {children}
    </RequestContext.Provider>
  );
};

const useRequest = () => {
  const state = React.useContext(RequestContext);
  return state;
};

export { RequestContextProvider, useRequest };
