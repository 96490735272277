import React from "react";
import { useRouter } from "next/router";
import { REACT_QUERY_STATUS } from "src/constants/reactQueryStatus";
import { createErrorToast } from "./useToast";
import { getToken, setToken, clearToken } from "src/utils/tokenHelper";

import { useUserLoginWithGoogleMutation } from "./queries/mutations/UserCredential/useUserLoginWithGoogleMutation.js";
import { useUserLoginMutation } from "./queries/mutations/UserCredential/useUserLoginMutation";

export const useAuth = () => {
  const [error, setError] = React.useState();
  const { push } = useRouter();

  const isAuthenticated = () => {
    return Boolean(getToken());
  };

  const handleOnSuccessLogin = ({ json, headers }) => {
    const { data: userInfo } = json;

    const token = headers.get("Authorization");

    setToken(JSON.stringify({ token, userInfo }));

    push("/home");
  };

  const { mutateAsync: _handleUserLogin, status: _userLoginStatus } =
    useUserLoginMutation({
      config: {
        onSuccess: handleOnSuccessLogin,
        onError: data => {
          const { json } = data;
          /**
           * If login error and response data have a user_id
           * direct to verify account page
           */
          if (json?.data?.user_id) {
            push(`/verify-account/${json.data.user_id}`);
            createErrorToast(json.message);
            return;
          }

          if (json?.message) {
            setError(json.message);
            return;
          }

          // error message when server BE is down
          createErrorToast("Service Unavailable", {
            autoClose: false,
            toastId: "SERVICE_UNAVAILABLE"
          });
        }
      }
    });

  const {
    mutateAsync: _handleUserLoginWithGoogle,
    status: _loginWithGoogleStatus
  } = useUserLoginWithGoogleMutation({
    config: {
      onSuccess: handleOnSuccessLogin
    }
  });

  const isLoginLoading = _userLoginStatus === REACT_QUERY_STATUS.LOADING;

  const isLoginWithGoogleLoading =
    _loginWithGoogleStatus === REACT_QUERY_STATUS.LOADING;

  /**
   * Logout
   */
  const logout = async () => {
    clearToken();
    push("/");
  };

  return {
    isAuthenticated,
    hanldeLogin: _handleUserLogin,
    handleLoginWithGoogle: _handleUserLoginWithGoogle,
    isLoginLoading,
    isLoginWithGoogleLoading,
    logout,
    error,
    setError
  };
};
