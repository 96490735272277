export const colors = {
  primary: "#21418A", //mandala["blue-ddl"][700]
  secondary: "#F37021", //mandala.orange[700]
  tertiary: "#8FD5DF",

  mandala: {
    black: {
      50: "#FFF",
      100: "#F6F6F9",
      200: "#EBEDF0",
      300: "#D0D6DC",
      400: "#9FA9B2",
      500: "#848D98",
      600: "#4E5660",
      700: "#2E363F",
      800: "#171B20",
      900: "#000000"
    },
    blue: {
      50: "#FEFEFF",
      100: "#F7FAFF",
      200: "#EFF7FF",
      300: "#95C5F7",
      400: "#59A0E8",
      500: "#2F83E1",
      600: "#1E6BC1",
      700: "#1B5BA5",
      800: "#164985",
      900: "#07274D"
    },
    // new blue color of DDL restyling
    "blue-ddl": {
      50: "#FBFCFF",
      100: "#F4F8FF",
      200: "#E7EEFF",
      300: "#B7CDFF",
      400: "#6795FF",
      500: "#4876E0",
      600: "#2552B8",
      700: "#21418A", // primary color
      800: "#1F3262",
      900: "#091A34"
    },
    orange: {
      50: "#FFFCFB",
      100: "#FFF8F4",
      200: "#FFEADD",
      300: "#FFCFB2",
      400: "#FFAF80",
      500: "#FF9555",
      600: "#FF7A29",
      700: "#F37021", // secondary color
      800: "#8F3600",
      900: "#3F1800"
    },
    red: {
      50: "#FFFCFC",
      100: "#FFF6F6",
      200: "#FFE8E8",
      300: "#FFBBBB",
      400: "#FF6565",
      500: "#EC5050",
      600: "#E63333",
      700: "#CB2222",
      800: "#862A2A",
      900: "#3C0000"
    },
    green: {
      50: "#FBFFFB",
      100: "#F0FFF0",
      200: "#D2FFD5",
      300: "#9BFFA0",
      400: "#7BEE81",
      500: "#56D05C",
      600: "#47BE4D",
      700: "#3DA942",
      800: "#1C5C1F",
      900: "#003C06"
    },

    yellow: {
      50: "#FFFEF9",
      100: "#FFFAE9",
      200: "#FFF6D6",
      300: "#FFEDAF",
      400: "#FFE484",
      500: "#FBD758",
      600: "#EFC83C",
      700: "#D9AE19",
      800: "#A7850F",
      900: "#574300"
    },

    chart: {
      blue: "#2F83E1",
      red: "#D76666",
      green: "#56D05C",
      orange: "#EA7E00",
      purple: "#993DB9",
      "dark-blue": "#014D77"
    },

    pipeline: {
      green: "#60D736",
      blue: "#1B5BA5",
      red: "#EC5050",
      yellow: "#FBD758",
      gray: "#9FA9B2"
    },

    table: {
      blue: "#E1EFFC",
      green: "#DCFFDC",
      red: "#FFE5E5",
      purple: "#FFE4F7",
      yellow: "#FFF0DB"
    }
  }
};
