import { colors } from "../foundations/colors";

export const linkStyles = {
  variants: {
    transparent: {
      fontWeight: "700",
      color: colors.mandala.blue[700],
      _hover: {
        boxShadow: "none",
        textDecoration: "none"
      },
      _focus: {
        boxShadow: "none",
        textDecoration: "none"
      },
      _active: {
        boxShadow: "none",
        textDecoration: "none"
      }
    },
    "ddl-sidebar-item": ({ isSelected = false, isOnSamePageBasePath }) => ({
      display: "flex",
      alignItems: "center",
      borderLeft: "4px solid",
      borderLeftColor:
        isSelected || isOnSamePageBasePath ? "mandala.blue.700" : "transparent",
      borderRadius: 0,
      width: "inherit",
      py: ".5em",
      px: 2,
      whiteSpace: "nowrap",
      justifyContent: "flex-start",
      fontSize: "md",
      fontWeight: isSelected ? 700 : 400,
      color: isSelected ? colors.mandala.blue[600] : colors.mandala.black[800],

      backgroundColor:
        isSelected || isOnSamePageBasePath
          ? colors.mandala.black[200]
          : "transparent",
      _hover: {
        backgroundColor: colors.mandala.black[200],
        borderLeftColor: colors.mandala.blue[700],
        color: colors.mandala.blue[600],
        textDecoration: "none",
        fontWeight: 700
      }
    })
  }
};
