import React from "react";
import { useAuth } from "src/hooks/useAuth";

const AuthContext = React.createContext({});

/**
 * Auth Context Provider
 *
 * InitialValues for dependency injection (test)
 *
 * state are being destructure last, so putting function in initialValues will
 * mock the functions
 */
const AuthContextProvider = ({ initialValues = {}, children }) => {
  const value = useAuth();

  return (
    <AuthContext.Provider value={{ ...value, ...initialValues }}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Auth values getter
 */
const useAuthContext = () => {
  return React.useContext(AuthContext);
};

export { AuthContextProvider, useAuthContext };
